import React, { useEffect, useState, useRef } from "react";
import BlogHeader from "../newinterface/blogHeader";
import Footer from "../newinterface/footer";
import OwlCarousel from "react-owl-carousel";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

function NewSAHomePage() {
  const [slideIndex, setSlideIndex] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);
  const sliderRef = useRef(null);

  const slides = [
    {
      title: " SF Bay Area ",
      city: " Santa Clara, California",
      img: "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/BayArea.jpg",
    },
    {
      title: " Los Angeles ",
      city: " Los Angeles, California",
      img: "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/LA_cobalt.jpg",
    },
    {
      title: " Seattle ",
      city: "  Seattle, Washington",
      img: "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/Seattle_Stazion.jpg",
    },
    {
      title: " Austin ",
      city: "   Austin, Texas",
      img: "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/Austin_Amlion2nd.jpg",
    },
    {
      title: " New York ",
      city: " Brooklyn, New York",
      img: "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/NY_AvalonFortGreen.jpg",
    },
    {
      title: " Washington DC ",
      city: " L Street Nw, Washington",
      img: "https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/spotlightcities/Washington+DC1.jpeg",
    },
    {
      title: "  View all Cities  ",
      city: "  L Street Nw, Washington",
      img: "https://s3-us-west-1.amazonaws.com/suite-ch/19462/19462_b.jpg",
    },
  ];

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    dots: true,
    speed: 300,
    infinite: true,
    autoplaySpeed: 5000,
    autoplay: false,
    afterChange: () => setUpdateCount(updateCount + 2),
    beforeChange: (current, next) => setSlideIndex(next),
    customPaging: (i) => (
      <button className="pager__item">{slides[i].title}</button> // ✅ Static titles in dots
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleCityClick = (index) => {
    setSlideIndex(index);
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    } else {
      console.error("Slider reference is not assigned.");
    }
  };
  return (
    <div className="SAweb_updateUi ">
      <BlogHeader />
      <div className="home-new">
        <div id="hero" class="aos-init aos-animate" data-aos="fade-up">
          <div class="banner-text">
            <h2 class=" ">Experience business hospitality at its finest</h2>
            <p class=" ">
              Whether it's relocation for a new job, a temporary work
              assignment, a short work trip, or a month-long getaway, our
              experiences are crafted to exceed your expectations.
            </p>
            <div class="btn btn-callback">Reserve Now</div>
          </div>
        </div>

        <div className="slider-container  center-slider">
          <Slider
            ref={sliderRef}
            {...settings}
            className="slider center-slider"
          >
            {slides.map((slide, index) => (
              <div key={index} className="slide">
                <img src={slide.img} alt={slide.name} />
                <div className="slide-content">
                  <p>{slide.city}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        {/* <div className="city-navigation mt-5">
          {cities.map((city, index) => (
            <button
              key={index}
              className={`city-button ${slideIndex === index ? "active" : ""}`}
              onClick={() => {
                handleCityClick(index);
              }}
            >
              {city.name}
            </button>
          ))}
        </div>
        <div className="mt-5">
          <Slider ref={sliderRef} {...settings}>
            {cities.map((city, index) => (
              <div key={index} className="slide">
                <img src={city.image} alt={city.name} />
                <div className="location-indicator">
                  <div className="location-dot"></div>
                  <span>{city.location}</span>
                </div>
              </div>
            ))}
          </Slider>
        </div> */}

        <section className="SAwebour_commitment text-dark text-center bg-white py-5">
          <div className="container">
            <h3 className="breakPoint">
              Our commitment - to make every experience a memorable one.
            </h3>
            <div className="box my-5">
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/star-w.png"
                    width="40px"
                    alt=""
                  />
                </div>
                <p>30+ years of Excellence</p>
              </div>
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/World_Beating_Tech_W.svg"
                    width="40px"
                    alt=""
                  />
                </div>
                <p>Award Winning Technology</p>
              </div>
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/Elegant_Properties_W.svg"
                    width="30px"
                    alt=""
                  />
                </div>
                <p>170+ Elegant Properties</p>
              </div>
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/Confirmed_Stays_W.svg"
                    width="31px"
                    alt=""
                  />
                </div>
                <p>1 Million +</p>
                <p>Stays</p>
              </div>
            </div>
          </div>
        </section>

        <section
          className="SAweb_makeBest bg-white py-5 aos-init aos-animate"
          data-aos="fade-up"
        >
          <div className="container">
            <h2 className="text-center pt-5 pb-3">What makes us the best</h2>
            <div className="boxTails">
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/makeBest-1.jpg"
                    alt=""
                  />
                </div>
                <div className="text-view">
                  <h3>A legacy of 30+ years</h3>
                  <p>
                    For over 30 years, the best in the business have trusted us
                    by putting the comfort and hospitality of their employees in
                    our hands.
                  </p>
                </div>
              </div>
              <div className="item flex-row-reverse">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/makeBest-2.jpg"
                    alt=""
                  />
                </div>
                <div className="text-view">
                  <h3>Attention to detail</h3>
                  <p>
                    For us, the details are everything. Before you make the big
                    move, our service team ensures your important criteria are
                    taken care of.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/makeBest-3.jpg"
                    alt=""
                  />
                </div>
                <div className="text-view">
                  <h3>Nationwide footprint, global standards</h3>
                  <p>
                    Our premier serviced apartments provide coast-to-coast
                    options in some of the most coveted neighborhoods.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_expDrive bg-white pt-5">
          <div className="box">
            <div className="image">
              <img
                src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/experiencePic.jpg"
                alt=""
              />
              <div className="caption">
                <h4>Technology-driven</h4>
                <h2>Experience</h2>
              </div>
            </div>
            <div className="item">
              <h2>Indulge in our stellar technology</h2>
              <p>
                At SuiteAmerica, we pride ourselves on developing proprietary
                technologies that allow us to provide you customized services in
                line with your needs.
              </p>
              <p>
                We use just the right amount of tech at every step to deliver
                faster response times, increase efficiencies, streamline
                communication, and deliver a better experience at all times.
              </p>
              <ul
                data-aos="fade-left"
                data-aos-easing="ease-in-sine"
                data-aos-duration="1000"
                className="aos-init"
              >
                <li>
                  <div className="gif_pic pic">
                    <img src="assets/newUI-images/homepage/tick-18.gif" />
                  </div>
                  100% Cloud
                </li>
                <li>
                  <div className="gif_pic pic">
                    <img src="assets/newUI-images/homepage/tick-18.gif" />
                  </div>
                  120+ APIs
                </li>
                <li>
                  <div className="gif_pic pic">
                    <img src="assets/newUI-images/homepage/tick-18.gif" />
                  </div>
                  Secure &amp; Scalable
                </li>
              </ul>
              <div>
                <a href="/technology" className="btn black-btn btn-border">
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_gHousingGuestExpc SAweb_homeYourStay bg-white">
          <div className="warapperDiv">
            <div className="row align-items-center">
              <div className="col-sm-7">
                <div data-aos="fade-up">
                  <h2>Facilitating Your Stay With Our Technology Suite</h2>
                  <p>
                    Raise service requests, explore the neighborhood or check
                    your reservation information and property details with our
                    secure suite of Web and Mobile Applications.
                  </p>
                  <a href="/technology" className="btn black-btn">
                    {" "}
                    Learn More
                  </a>
                </div>
              </div>
              <div className="col-sm-5">
                <div className="tailBox">
                  <a href="/guest-login">
                    <div className="item" data-aos="fade-up">
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/laptop.png"
                        alt=""
                      />
                      <div>
                        <h3>Guest Portal</h3>
                        <p>
                          An interactive portal to access information from
                          anywhere.
                        </p>
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://apps.apple.com/app/guestportal/id1492423942"
                    target="_blank"
                  >
                    <div className="item" data-aos="fade-up">
                      <img
                        src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/mobileApp.png"
                        alt=""
                      />
                      <div>
                        <h3>Mobile App</h3>
                        <p>
                          User-friendly mobile app to personalize your stay on
                          the go.
                        </p>
                      </div>
                    </div>
                  </a>
                  <div className="item" data-aos="fade-up">
                    <img
                      src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/gHousing/apple-tv.png"
                      alt=""
                    />
                    <div>
                      <h3>TV App</h3>
                      <p>
                        An App on your TV so you’re in control of the
                        nitty-gritty throughout your stay.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_gHousingGuestExpc bg-white py-5">
          <div className="warapperDiv container py-3 appStoreViewParant">
            <div className="row align-items-center" data-aos="fade-up">
              <div className="col-sm-6">
                <div>
                  <h1 className="m-0">Your ultimate</h1>
                  <h1>stay hub</h1>
                  <p className="p">Reduced touchpoints</p>
                  <ul className="appStore-listview">
                    <li>Our app centralizes everything</li>
                    <li>
                      <span>
                        <img
                          src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/icons/chapp_webapp.svg"
                          alt="laptop_icon"
                          width="55"
                        />
                      </span>
                      <span>One spot for stay info</span>
                    </li>
                    <li>
                      <span>
                        <img
                          src="https://suiteamerica.com/images/icons/touch_icon.png"
                          alt=""
                          width="55"
                        />
                      </span>
                      <span>Submit service requests</span>
                    </li>
                    <li>
                      <span>
                        <img
                          src="https://suiteamerica.com/images/icons/time.png"
                          alt=""
                          height="35"
                        />
                      </span>
                      <span>24/7 support</span>
                    </li>
                    <li className="mt-5">
                      <a
                        href="https://apps.apple.com/app/guestportal/id1492423942"
                        target="_blank"
                      >
                        <img
                          src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                          alt="app store"
                          width="150"
                        />
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.suiteamerica.guestportal&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                        target="_blank"
                        className="mrg-left-10"
                      >
                        <img
                          src="https://suiteamerica.com/assets/newUI-images/google-play-store-badge.svg"
                          alt="Play store"
                          height="50"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 text-center">
                <img
                  width="90%"
                  src="https://suiteam-website.s3.us-west-1.amazonaws.com/images-website/iphone-mockup.png"
                  alt="mobile app image"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_chInfo SAweb_guestExp">
          <div className="container">
            <h2 className="my-5 pt-5 pb-3 text-center" data-aos="fade-up">
              Stay, the SuiteAmerica Way
            </h2>
            <div className="statBox pb-5" data-aos="fade-up">
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/workout.png"
                    alt=""
                    width="50px"
                  />
                </div>
                <h5>The Hustle Bubble</h5>
                <p>
                  A fully-equipped fitness center to keep you on top of your
                  game
                </p>
              </div>
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/house.png"
                    alt=""
                    width="40px"
                  />
                </div>
                <h5>Parking Stations</h5>
                <p>A reserved parking space to rest your lean-mean machines</p>
              </div>
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/pool.png"
                    alt=""
                    width="50px"
                  />
                </div>
                <h5>Pool of Tranquility</h5>
                <p>
                  The perfect place to wash off all the stresses of your day
                </p>
              </div>
              <div className="item">
                <div className="image">
                  <img
                    src="https://suiteam-website.s3.us-west-1.amazonaws.com/new/images/stayIcons/wifi.png"
                    alt=""
                    width="40px"
                  />
                </div>
                <h5>Connection Bay</h5>
                <p>High-speed WiFi that keeps you connected at all times</p>
              </div>
            </div>
          </div>
        </section>

        <section className="SAweb_guestSlider bg-white pt-5" data-aos="fade-up">
          <div className="container SAweb_owl-slider2 py-1">
            <h2 className="pt-3">What our guests have to say</h2>
            <div className="owl-slider SAweb_sliderNav my-5 pb-5">
              <OwlCarousel
                className="owl-theme owl-carousel"
                {...{
                  loop: false,
                  margin: 20,
                  nav: false,
                  dots: true,
                  items: 1,
                  autoplay: false,
                  navContainerClass: "owl-buttons",
                  navText: [
                    "<i class='glyphicon glyphicon-menu-left'></i>",
                    "<i class='glyphicon glyphicon-menu-right'></i>",
                  ],
                  responsive: {
                    767: { items: 2, slideBy: 2, loop: false },
                    992: { items: 2, slideBy: 2, loop: false },
                    1199: { items: 3, slideBy: 3, loop: false },
                  },
                }}
              >
                <div className="item">
                  <div className="caption">
                    <i className="bi bi-quote"></i>
                    <h1 className="animated bounce text-white">
                      Key highlighting line
                    </h1>

                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo
                    </p>
                    <h3>Username, Location</h3>
                    <i className="bi bi-quote"></i>
                  </div>
                </div>
                <div className="item">
                  <div className="caption">
                    <i className="bi bi-quote"></i>
                    <h1 className="animated bounce text-white">
                      Key highlighting line
                    </h1>

                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                    </p>
                    <h3>Username, Location</h3>
                    <i className="bi bi-quote"></i>
                  </div>
                </div>
                <div className="item">
                  <div className="caption">
                    <i className="bi bi-quote"></i>
                    <h1 className="animated bounce text-white">
                      Key highlighting line
                    </h1>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo
                    </p>
                    <h3>Username, Location</h3>
                    <i className="bi bi-quote"></i>
                  </div>
                </div>
                <div className="item">
                  <div className="caption">
                    <i className="bi bi-quote"></i>
                    <h1 className="animated bounce text-white">
                      Key highlighting line
                    </h1>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo
                    </p>
                    <h3>Username, Location</h3>
                    <i className="bi bi-quote"></i>
                  </div>
                </div>
                <div className="item">
                  <div className="caption">
                    <i className="bi bi-quote"></i>
                    <h1 className="animated bounce text-white">
                      Key highlighting line
                    </h1>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo
                    </p>
                    <h3>Username, Location</h3>
                    <i className="bi bi-quote"></i>
                  </div>
                </div>
                <div className="item">
                  <div className="caption">
                    <i className="bi bi-quote"></i>
                    <h1 className="animated bounce text-white">
                      Key highlighting line
                    </h1>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo
                    </p>
                    <h3>Username, Location</h3>
                    <i className="bi bi-quote"></i>
                  </div>
                </div>
                <div className="item">
                  <div className="caption">
                    <i className="bi bi-quote"></i>
                    <h1 className="animated bounce text-white">
                      Key highlighting line
                    </h1>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo
                    </p>
                    <h3>Username, Location</h3>
                    <i className="bi bi-quote"></i>
                  </div>
                </div>
                <div className="item">
                  <div className="caption">
                    <i className="bi bi-quote"></i>
                    <h1 className="animated bounce text-white">
                      Key highlighting line
                    </h1>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo
                    </p>
                    <h3>Username, Location</h3>
                    <i className="bi bi-quote"></i>
                  </div>
                </div>
                <div className="item">
                  <div className="caption">
                    <i className="bi bi-quote"></i>
                    <h1 className="animated bounce text-white">
                      Key highlighting line
                    </h1>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo
                    </p>
                    <h3>Username, Location</h3>
                    <i className="bi bi-quote"></i>
                  </div>
                </div>
                <div className="item">
                  <div className="caption">
                    <i className="bi bi-quote"></i>
                    <h1 className="animated bounce text-white">
                      Key highlighting line
                    </h1>
                    <p>
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo
                    </p>
                    <h3>Username, Location</h3>
                    <i className="bi bi-quote"></i>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default NewSAHomePage;
