import React from "react";
import Footer from "./footer";
import TransHeader from "./transheader";
import platform from "platform";
import GuestServicesapi from "../../../services/guestServicesapi";
import authDetails from "../../common/authDetails";
import ThirdPartyApiService from "../../../services/thirdPartyApis";
import SessionService from "../../../services/sessionService";
import showToast from "../../common/toast-notification/toast";
class ForceGuestLoginMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      message: "",
      color: "",
      loader: false,
      verifyemaillabel: false,
      verifyotplabel: false,
      clientId: "",
      clientSecret: "",
      OtpErrMsg: "",
      isOTPValid: false,
      passwordLessOtp: "",
      resendOtpTimer: 90,
      authCheckRes: {},
      resendMsg: false,
    };
  }
 
  async componentDidMount() {

    try{
        let forceLoginValue = JSON.parse(this.props.match.params.userData);
       
        if(forceLoginValue.userData.GuestEmail!=""){
            await this.setState({email:forceLoginValue.userData.GuestEmail})
            this.authentication();
        }
    }catch (error) {
    }
    

    
  }



  async authentication() {
    try {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      await this.setState({ loader: true });

      // if (this.state.email != "" && reg.test(this.state.email)) {
      //   const address = "000.00.000.000";
      //   const loginReqObj = {
      //     guestEmail: this.state.email,
      //     // guestPassword: this.state.auth0UserProfile.sub.split('|')[1],
      //     guestPassword: "",
      //     status: "guest",
      //     browsertype: platform.name,
      //     ipaddress: address,
      //   };

      //   const authCheckRes = await GuestServicesapi.guestUsersLogin(loginReqObj);
      //   if (authCheckRes.error == "1") {
      //     await this.setState({
      //       message: "Sorry, we couldn't find an account with that username.",
      //       color: "red",
      //       loader: false,
      //       socailloader: false,
      //     });
      //   } else {
      //     this.setState({
      //       authCheckRes: authCheckRes,
      //       message: "",
      //       loader: false,
      //       verifyemaillabel: true,
      //       verifyotplabel: true,
      //     });
      //     this.callOtp("email");
      //   }
      // } else {
      //   this.setState({
      //     message: "Enter valid email ",
      //     color: "red",
      //     loader: false,
      //   });
      // }
     
      if (this.state.email != "" && reg.test(this.state.email)) {
        const loginReqObj = {
          guestemail: this.state.email,
          guestPassword: "",
          status: "guest",
        };

        const authCheckRes = GuestServicesapi.guestUsersRegLogin(loginReqObj);

        authCheckRes
          .then((result) => {
            // Access the PromiseResult
            if (result.error == "1") {
              this.setState({
                message: result.message,
                color: "red",
                loader: false,
                socailloader: false,
              });
            } else {
              
              this.setState({
                authCheckRes: result.message,
                message: "",
                loader: false,
                verifyemaillabel: true,
                verifyotplabel: true,
              });
              this.getAuth0UserInfo();
            }
          })
          .catch((error) => {
            this.handleError(error);
          });
      } else {
      
          
        this.setState({
          message: "Enter valid email ",
          color: "red",
          loader: false,
        });
      }
    } catch (error) {
      console.log(error, "error");
      this.handleError(error);
    }
  }
 

 
  async getAuth0UserInfo() {
    try {
      const guestInfoReqObj = {
        userData: this.state.authCheckRes,
        type: "guest",
      };
      localStorage.setItem("guestSession", this.state.authCheckRes.sessionId);
      await SessionService.SessionStore(guestInfoReqObj);
      
      const GetResponse = await SessionService.SessionGet({ type: "guest" });
      if (GetResponse.roleId == 2) {
        const guestDetails = {
          orderUid: GetResponse.OrderUId,
        };
        var guestEmailObj = {
          guestEmail: GetResponse.GuestEmail,
          status: "active",
        };
        const GetOrdersByGuestEmailResponse =
          await GuestServicesapi.GetOrdersByGuestEmail(guestEmailObj);

        if (GetOrdersByGuestEmailResponse.error != 1) {
          const tripInfoData = await GuestServicesapi.GetTripInfo(guestDetails);
          if (tripInfoData == null) {
            location.href = "/mobile-reservations";
            //this.setState({ loader: false })
          } else {
            location.href = "/mobile-reservations";
            //this.setState({ loader: false })
          }
        } else {
          this.handleError(GetOrdersByGuestEmailResponse);
          location.href = "/mobile-reservations";
          // this.setState({ loader: false })
        }
      } else {
        location.href = "/";
      }
    } catch (error) {
      this.handleError(error);
    }
  }


  render() {
    return (
      <div>
        loading ...
      </div>
    );
  }
}
export default ForceGuestLoginMobile;
