import React, { useEffect, useState } from "react";
import Header from "../../newinterface/header";
import Footer from "../../newinterface/footer";
import showToast from "../../../common/toast-notification/toast";
import { useHistory } from "react-router-dom";

function NewInstantReserveBook() {
  const [state, setState] = useState({
    loading: false,
    loadingStatus: true,
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNo: "",
    notifyGuest: false,
    arrivalDate: null,
    arrivalTime: null,
    airline: "",
    flight: "",
    notes: "",
    checkin: null,
    checkout: null,
    communityData: {},
    termsCheck: false,
    paymentMode: false,
  });
  const [errors, setErrors] = useState({});

  const history = useHistory();

  function updateState(updateObj) {
    setState((prevState) => ({ ...prevState, ...updateObj }));
  }

  function handleError(err) {
    setState({ loadingStatus: false });
    showToast(err);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    try {
      $(document).ready(function () {
        jQuery(function ($) {
          $("#phoneNumber").intlTelInput();
        });
      });
      const savedState = localStorage.getItem("reserveState");
      let normalObject = JSON.parse(savedState);
      if (normalObject) {
        updateState({
          ...normalObject,
          checkin: normalObject.checkin,
          checkout: normalObject.checkout,
          communityData: normalObject.communities,
          loadingStatus: false,
        });
        // localStorage.removeItem("reserveState");
      }
    } catch (error) {
      handleError(error);
    }
  }, []);

  const backToPropertyDetails = () => {
    let data = state.communityData;
    if (data) {
      history.push(
        "/instant-booking-details/" +
          data.community_name.replace(/\/| /g, "-") +
          "-" +
          data.masterId
      );
    }
  };
  useEffect(() => {
    $(document).ready(function () {
      jQuery(function ($) {
        $("#phoneNumber").intlTelInput();
      });
    });
  }, [state.paymentMode]);

  return (
    <div className="SAweb_updateUi">
      <Header />
      <div class="property-info">
        <div class="container">
          <div class="row">
            {!state.paymentMode ? (
              <div class="col-md-7 col-lg-8 brdr-right">
                <div class="backto">
                  <img
                    src="/assets/newUI-images/instantBook/back-arrow.svg"
                    alt=""
                    onClick={() => backToPropertyDetails()}
                  />
                  <span onClick={() => backToPropertyDetails()}>
                    View Property Info
                  </span>
                </div>
                <div class="guest-info">
                  <div class="title">
                    <h2>
                      <span>Guest Information</span>
                    </h2>
                  </div>
                  <form class="guest-form">
                    <div className="row">
                      <div className="col-md-11">
                        <div class="row">
                          <div class="form-group col-md-6">
                            <label for="fname" class="form-label">
                              First name
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Jack Sullivan"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="lname" class="form-label">
                              Last name
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Jack Sullivan"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="emailaddr" class="form-label">
                              Email address
                            </label>
                            <input
                              type="email"
                              class="form-control"
                              placeholder="JackSullivan@gmail.com"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="phone" class="form-label">
                              Phone
                            </label>
                            <input
                              type="text"
                              id="phoneNumber"
                              className="form-control"
                              placeholder="0000 000 000"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label for="cname" class="form-label">
                              Company Name (Optional)
                            </label>
                            <input
                              type="text"
                              class="form-control"
                              placeholder="JackSullivan@gmail.com"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="guest-info review-pay">
                  <div class="title">
                    <h2>
                      <span>Guest Information</span>
                    </h2>
                    <p>You're just one step away from reserving your stay.</p>
                  </div>
                  <div class="payment-card">
                    <h3>Payment Summary</h3>
                    <ul class="payment-item">
                      <li>
                        <span>Subtotal</span>
                        <span>$330.00</span>
                      </li>
                      <li>
                        <span>Service Fee</span>
                        <span>$20.00</span>
                      </li>
                      <li>
                        <span>Total</span>
                        <span>$350.00</span>
                      </li>
                    </ul>
                  </div>
                  <div class="accept-card">
                    <div class="card-info">
                      <img
                        src="/assets/newUI-images/instantBook/lock.svg"
                        alt=""
                      />
                      <p>
                        <span>We accept all major credit and debit cards.</span>
                        <span>
                          {" "}
                          Your payment is encrypted and processed securely.
                        </span>
                      </p>
                    </div>
                  </div>
                  <p class="guest-terms">
                    By booking, you agree to our Guest Terms and Payment Policy.
                    Your reservation will be finalized upon your background
                    check.
                  </p>
                  <button
                    class="btn btn-continue"
                    onClick={() => {
                      updateState({ paymentMode: true }), window.scrollTo(0, 0);
                    }}
                  >
                    Continue to Book
                  </button>
                </div>
              </div>
            ) : (
              <div class="col-md-7 col-lg-8 brdr-right">
                <div class="backto">
                  <img
                    src="/assets/newUI-images/instantBook/back-arrow.svg"
                    alt=""
                    onClick={() => updateState({ paymentMode: false })}
                  />
                  <span onClick={() => updateState({ paymentMode: false })}>
                    Booking Details
                  </span>
                </div>
                <div class="guest-info">
                  <div class="title">
                    <h2>
                      <span>Secure Payment</span>
                    </h2>
                  </div>
                  <form class="payment-form guest-form">
                    <div class="row">
                      <div class="col-md-12 col-lg-6">
                        <div class="form-group ">
                          <label for="emailaddr" class="form-label">
                            Email{" "}
                          </label>
                          <input
                            type="email"
                            class="form-control"
                            placeholder="JackSullivan@gmail.com"
                          />
                        </div>

                        <div class="form-group ">
                          <label for="cinfo" class="form-label">
                            Card information
                          </label>
                          <div className="input-addon">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="0000 0000 0000 0000"
                            />
                            <img
                              src="/assets/newUI-images/instantBook/mastercard.svg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group ">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="MM/YY"
                              />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group ">
                              <input
                                type="text"
                                class="form-control"
                                placeholder="CVC"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="form-group ">
                          <label for="ncard" class="form-label">
                            Name on card{" "}
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder=""
                          />
                        </div>
                        <div class="form-group ">
                          <label for="country" class="form-label">
                            Country or region
                          </label>
                          <select class="form-control form-select">
                            <option>+1 (555) 000-0000</option>
                            <option>+1 (555) 000-0000</option>
                            <option>+1 (555) 000-0000</option>
                            <option>+1 (555) 000-0000</option>
                          </select>
                        </div>
                        <div class="form-group">
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="savecheck"
                            />
                            <label class="form-check-label" for="savecheck">
                              Save my payment details for faster checkout next
                              time.
                            </label>
                          </div>
                        </div>
                        <div class="form-group">
                          <button class="btn btn-continue w-100">
                            Pay $350.00
                          </button>
                        </div>
                        <p class="byclick ">
                          By clicking “Pay Now,” you authorize SuiteAmerica to
                          charge your selected payment method for the total
                          amount and store your details securely via Stripe.
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}

            <div class="col-md-5 col-lg-4 mobile-card-gap">
              <div class=" guest-right">
                <div class="guest-img">
                  <figure>
                    <img
                      src="/assets/newUI-images/instantBook/carousel-guest.png"
                      alt=""
                    />
                  </figure>
                  <div class="hotel-title">
                    <h4>10TH @ HOYT</h4>
                    <p>925 Nw Hoyt Street, Portland, Oregon 97209</p>
                  </div>
                  <div class="hotel-amenity">
                    <ul>
                      <li>
                        <span>
                          <img
                            src="/assets/newUI-images/instantBook/single-bed.svg"
                            alt=""
                          />
                        </span>
                        <span>2 Bedroom</span>
                      </li>
                      <li>
                        <span>
                          <img
                            src="/assets/newUI-images/instantBook/tub.svg"
                            alt=""
                          />
                        </span>
                        <span>2 Bathroom</span>
                      </li>
                    </ul>
                  </div>
                  <p class="booked-date">April 4, 2025 | April 28, 2025</p>
                  <div class="pricing-down">
                    <h3>Pricing Breakdown</h3>
                    <ul class="payment-item">
                      <li>
                        <span>Base Rent</span>
                        <span>$330.00</span>
                      </li>
                      <li>
                        <span>Utilities</span>
                        <span>$330.00</span>
                      </li>
                      <li>
                        <span>Fees & Insurance</span>
                        <span>$330.00</span>
                      </li>
                      <li>
                        <span>Total</span>
                        <span>$330.00</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="gray-box">
                  <figure>
                    <img
                      src="/assets/newUI-images/instantBook/tick-circle.svg"
                      alt=""
                    />
                  </figure>
                  <div class="box-title">
                    <h3>Instant Confirmation</h3>
                    <p>
                      Once you complete your reservation, you’ll receive instant
                      confirmation. Your booking is guaranteed and secured
                      immediately.
                    </p>
                  </div>
                </div>
                <div class="gray-box">
                  <figure>
                    <img
                      src="/assets/newUI-images/instantBook/hidden-fee.svg"
                      alt=""
                    />
                  </figure>
                  <div class="box-title">
                    <h3>No Hidden Fees</h3>
                    <p>
                      {" "}
                      The price you see is the price you pay—no surprise
                      charges.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default NewInstantReserveBook;
