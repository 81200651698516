import toast from "react-hot-toast";

const customToastStyle = {
  background: "#333",
  color: "#fff",
  padding: "10px",
  borderRadius: "4px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: "350px",
  position: "relative", // Added to position emojis
};

const closeButtonStyle = {
  background: "transparent",
  color: "#fff",
  border: "none",
  cursor: "pointer",
  width: "25px",
  position: "absolute",
  top: "5px",
  right: "5px",
};

const CustomToast = ({ message, t }) => (
  <div style={customToastStyle}>
    <span role="img" aria-label="Caution">
      ⚠️
    </span>
    <p style={{ margin: "10px", flex: 1 }}>{message}</p>
    <button onClick={() => toast.dismiss(t.id)} style={closeButtonStyle}>
      ✕
    </button>
  </div>
);

function showToast(message) {
  toast.custom((t) => <CustomToast t={t} message={message} />, {
    duration: 6000,
    style: {
      background: "#333",
      color: "#fff",
      marginTop: "20px",
    },
  });
}

export default showToast;
