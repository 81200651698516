var _ = require("lodash");

var urlsList = [
  {
    url: "/corporation-corporate-housing",
    title:
      "Corporate Housing | Temporary Corporate Housing |  800-367-9501 | SuiteAmerica",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Temporary Corporate Hous" +
      "ing solutions.  SuiteAmerica 800 367 9501, solutions for Corporate Housing are f" +
      "ully customizable come with 24/7 support & exclusive personalized meet & greet s" +
      "ervices.",
    canonical: "https://www.suiteamerica.com/corporation-corporate-housing",
  },
  {
    url: "/corporation-global",
    title:
      "Global Corporate Housing | Global Serviced Apartments | 800 367 9501 | SuiteAmerica",
    description:
      "SuiteAmerica provides game changing Global Corporate Housing & Global Serviced A" +
      "partments.  SuiteAmerica 800 367 9501, we’ve developed a streamlined Global Corp" +
      "orate Housing program to ensure your transferees have the best corporate relocat" +
      "ion housing stay possible.",
    canonical: "https://www.suiteamerica.com/corporation-global",
  },
  {
    url: "/corporation-destination-services",
    title:
      "Corporate Housing Destination Services | 800 367 9501 | SuiteAmerica",
    description:
      "SuiteAmerica provides platinum-level Corporate Housing Destination Services.  At" +
      " SuiteAmerica 800-367-9501, we’re using game-changing technology, 24/7 service a" +
      "nd our nearly 30 years of excellence in customer service to revolutionize the Co" +
      "rporate Housing Destination Services experience around the globe.",
    canonical: "https://www.suiteamerica.com/corporation-destination-services",
  },
  {
    url: "/technology",
    title:
      "Corporate Housing Technology | Corporate Housing 24/7 Support | 800 367 9501 | SuiteAmerica",
    description:
      "SuiteAmerica provides game changing Corporate Housing Technology. SuiteAmerica 8" +
      "00 367 9501, solutions for Corporate Housing Technology are fully customizable c" +
      "ome with 24/7 support & exclusive personalized meet & greet services.",
    canonical: "https://www.suiteamerica.com/technology",
  },
  {
    url: "/customization",
    title:
      "Customized Corporate Housing Solutions | 800 367 9501 | SuiteAmerica",
    description:
      "SuiteAmerica provides platinum-level Customized Corporate Housing Solutions.  At" +
      " SuiteAmerica 800-367-9501, we’re using game-changing Customized Corporate Housi" +
      "ng Solutions,  24/7 service and our nearly 30 years of excellence in customer se" +
      "rvice to revolutionize the Corporate Housing Destination Services experience aro" +
      "und the globe.",
    canonical: "https://www.suiteamerica.com/customization",
  },
  {
    url: "/relocation-corporate-housing",
    title:
      "Corporate Relocation Services | Relocation Housing |  800-367-9501 | SuiteAmeric" +
      "a",
    description:
      "SuiteAmerica provides first class Corporate Relocation Services & Relocation Hou" +
      "sing support.  At SuiteAmerica 800 367 9501, we’ve developed a streamlined globa" +
      "l program to ensure your transferees have the best corporate relocation housing " +
      "stay possible.",
    canonical: "https://www.suiteamerica.com/relocation-corporate-housing",
  },
  {
    url: "/global",
    title:
      "Global Relocation Housing | Temporary Global Apartments |  800-367-9501 | SuiteAmerica",
    description:
      "SuiteAmerica provides award winning Global Relocation Housing and Relocation Ser" +
      "vices. At SuiteAmerica 800 367 9501, we're the one stop solution for a streamlin" +
      "ed experience that ensures your transferee's global transition is as seamless as" +
      " possible.",
    canonical: "https://www.suiteamerica.com/global",
  },
  {
    url: "/destination-services",
    title:
      "Relocation Destination Services | Relocating Services|  800-367-9501 | SuiteAmerica",
    description:
      "SuiteAmerica's Relocation and Destination Services 800 367 9501 provide personal" +
      "ized home finding tours and relocation services to give your transferee the abil" +
      "ity to relocate utilizing cutting edge technology that streamlines the relocatio" +
      "n process and offers 24/7 support.",
    canonical: "https://www.suiteamerica.com/destination-services",
  },
  {
    url: "/relocation-technology",
    title:
      "Relocation Technology | Relocating Support|24/7 Technology| 800-367-9501 | SuiteAmerica",
    description:
      "SuiteAmerica's streamlined Relocation Technology provides a one stop solution wi" +
      "th reduced points of contact, constant live updates, and innovative technology t" +
      "hat's efficient and responsive. Your transferre's relocation will be managed eff" +
      "ectively and efficiently at SuiteAmerica 800 367 9501.",
    canonical: "https://www.suiteamerica.com/relocation-technology",
  },
  {
    url: "/relocation-customization",
    title:
      "Relocation Customization| Relocation Personalization| 800-367-9501 | SuiteAmerica",
    description:
      "SuiteAmerica provides Customized Relocation amenities, features, and packages. T" +
      "hrough SuiteAmerica's 800 367 9501 Relocation Customization features, your trans" +
      "feree's relocation will feel like home with personalized relocating packages and" +
      " amenities.",
    canonical: "https://www.suiteamerica.com/relocation-customization",
  },
  {
    url: "/government-housing",
    title:
      "GSA Contract Housing | Government Housing | Global Accommodations | 800-367-9501" +
      " | SuiteAmerica",
    description:
      "SuiteAmerica provides low-cost GSA Contract Housing, Government Housing & Superi" +
      "or Global Accommodations. SuiteAmerica 800-367-9501, cost effective Per Diem Hou" +
      "sing  housing solutions that assist government contractors in the field with ful" +
      "ly equipped and customized furnished apartments.",
    canonical: "https://www.suiteamerica.com/government-housing",
  },
  {
    url: "/government-technology",
    title:
      "Government Housing Technology | Corporate Government Housing Technology| 800-367" +
      "-9501 | SuiteAmerica",
    description:
      "SuiteAmerica's Corporate Government Housing Technology makes every government em" +
      "ployee's temporary government housing as stress-free as possible. Through SuiteA" +
      "merica's government housing technology 800 367 9501, government housing is now c" +
      "ost effective and convenient.",
    canonical: "https://suiteamerica.com/government-technology",
  },
  {
    url: "/government-customization",
    title:
      "Customized Government Housing | Personalized Government Housing| 800-367-9501 | SuiteAmerica",
    description:
      "SuiteAmerica's Customized Government Housing allows all government housing emplo" +
      "yees to feel at home with personalized features, packages, and amenities.  Suite" +
      "America's 800 367 9501 25 years of certified experience with corporate governmen" +
      "t housing guarantees a cost effective corporate government housing experience.",
    canonical: "https://suiteamerica.com/government-customization",
  },
  {
    url: "/individual-corporate-housing",
    title:
      "Temporary Living Accommodations | Short Term Housing |  800-367-9501 | SuiteAmer" +
      "ica",
    description:
      "SuiteAmerica provides stress-free Temporary Living Accommodations & Short Term H" +
      "ousing solutions.  SuiteAmerica 800-367-9501, let our Concierge Services team he" +
      "lp you settle in with customizable amenities and services. Day or night, our tea" +
      "m is here to open the door with a smile and provide a warm welcome.",
    canonical: "https://www.suiteamerica.com/individual-corporate-housing",
  },
  {
    url: "/individual-global",
    title:
      "Short Term Global Housing | Temporary Global Housing |  800-367-9501 | SuiteAmerica",
    description:
      "SuiteAmerica provides game changing Short Term Global Housing solutions.  SuiteA" +
      "merica 800 367 9501, solutions for Short Term Global Corporate Housing are fully" +
      " customizable come with 24/7 support & exclusive personalized meet & greet servi" +
      "ces.",
    canonical: "https://www.suiteamerica.com/individual-global",
  },
  {
    url: "/individual-destination-services",
    title: "Individual Destination Service | 800-367-9501 | SuiteAmerica",
    description:
      "SuiteAmerica provides first class Destination Services.  SuiteAmerica 800 367 95" +
      "01 our three-tiered, premium level Destination Services program is an industry g" +
      "ame changer. Period.",
    canonical: "https://www.suiteamerica.com/individual-destination-services",
  },
  {
    url: "/individual-technology",
    title:
      "Short Term Corporate Housing Technology| 800-367-9501 | SuiteAmerica",
    description:
      "SuiteAmerica provides platinum-level Short Term Corporate Housing Technology.  A" +
      "t SuiteAmerica 800-367-9501, we’re using game-changing technology, 24/7 service " +
      "and our nearly 30 years of excellence in customer service to revolutionize the S" +
      "hort Term Corporate Housing experience around the globe.",
    canonical: "https://www.suiteamerica.com/individual-technology",
  },
  {
    url: "/individual-customization",
    title:
      "Customized Short Term Housing | Customized Temporary Housing  | 800-367-9501 | SuiteAmerica",
    description:
      "Suite America provides Customized Short Term Housing.  SuiteAmerica 800 367 9501" +
      ", solutions for Short Term Housing are fully customizable come with 24/7 support" +
      " & exclusive personalized meet & greet services.",
    canonical: "https://www.suiteamerica.com/individual-customization",
  },
  {
    url: "/company-info",
    title: "About Us | SuiteAmerica | 800-367-9501",
    description:
      "SuiteAmerica: We are one of the fastest growing corporate housing" +
      " companies in the United States. Our culture stems from great leadership and our" +
      " dedication to creating experiences for our clients that are second to none.",
    canonical: "https://www.suiteamerica.com/company-info",
  },
  {
    url: "/contactUs",
    title: "Contact Us | SuiteAmerica | 800-367-9501",
    description:
      "Get in touch with SuiteAmerica. We're here for you 24/7 with over 30 years of ex" +
      "cellence in customer service to revolutionize the Corporate Housing experience a" +
      "round the globe.",
    canonical: "https://www.suiteamerica.com/contactUs",
  },
  {
    url: "/media-kit",
    title: "SuiteAmerica Media Kit | 800-367-9501",
    description:
      "Contact Suite America.  The following resources including but not limited to a b" +
      "rief corporate summary and breakdown of services have been provided on behalf of" +
      " SuiteAmerica for journalistic purposes. To obtain a PDF of the contents provide" +
      "d in this kit, please reach out to our Media Contact for more information.",
    canonical: "https://www.suiteamerica.com/media-kit",
  },
  {
    url: "/career-opportunities",
    title: "SuiteAmerica Career Opportunites | 800-367-9501",
    description:
      "SuiteAmerica Employment Opportunites.  Be a part of an award winning, Employee O" +
      "wned Company. Browse our current career opportunities.",
    canonical: "https://www.suiteamerica.com/career-opportunities",
  },
  {
    url: "/privacy-policy",
    title: "SuiteAmerica Privacy Policy | 800-367-9501",
    description:
      "SuiteAmerica Privacy Policy. SuiteAmerica is committed to ensuring that your pri" +
      "vacy is protected. Should we ask you to provide certain information by which you" +
      " can be identified when using this website, then you can be assured that it will" +
      " only be used in accordance with this privacy statement.",
    canonical: "https://www.suiteamerica.com/privacy-policy",
  },
  {
    url: "/testimonials",
    title: "SuiteAmerica Testimonials | 800-367-9501",
    description:
      "Here's a sample of SuiteAmerica Testimonials.  SuiteAmerica 800-367-9501, we’re " +
      "using game-changing technology, 24/7 service and our nearly 30 years of excellen" +
      "ce in customer service to revolutionize the Corporate Housing experience around " +
      "the globe.",
    canonical: "https://www.suiteamerica.com/testimonials",
  },
  {
    url: "/media-kit-contact",
    title: "SuiteAmerica Media Contact | 800 367 9501",
    description:
      "Reach SuiteAmerica's Media Contact to learn more about SuiteAmerica 800 367 9501" +
      " and our award winning Corporate Housing and Destination Services. Stay updated " +
      "on SuiteAmerica's temporary apartments and the Corporate Housing industry. Follo" +
      "w SuiteAmerica Social Media for more information.",
    canonical: "https://www.suiteamerica.com/media-kit-contact",
  },
  {
    url: "/media-kit-What-we-do",
    title: "SuiteAmerica What We Do | 800 367 9501",
    description:
      "SuiteAmerica 800 367 9501 sets an unrivaled standard of customer satisfaction th" +
      "rough our corporate housing services and short term furnished apartment amenitie" +
      "s. Learn about Who We Are and the game changing technology and level of service " +
      "behind SuiteAmerica's Corporate Housing industry success.",
    canonical: "https://www.suiteamerica.com/media-kit-What-we-do",
  },
  {
    url: "/media-kit-our-philosophy",
    title: "SuiteAmerica Our Philosophy | 800 367 9501",
    description:
      "The SuiteAmerica 800 367 9501 Philosophy centers on our culture. For almost 30 y" +
      "ears, SuiteAmerica's revolved around people. SuiteAmerica team members provide p" +
      "latinum level service to our corporate housing and temporary apartment guests. E" +
      "veryday, SuiteAmerica challenges itself to be the best corporate housing provide" +
      "r.",
    canonical: "https://www.suiteamerica.com/media-kit-our-philosophy",
  },
  {
    url: "/media-kit-housing-solutions",
    title:
      "SuiteAmerica Housing Solutions | Corporate Housing Solutions | 800 367 9501",
    description:
      "Learn about SuiteAmerica 800 367 9501 Housing Solutions. We provide corporate ho" +
      "using with furnished apartments. SuiteAmerica also offers certified government h" +
      "ousing and unfurnished apartments for temporary housing. Intern housing is avail" +
      "able along with SuiteAmerica's award winning service.",
    canonical: "https://www.suiteamerica.com/media-kit-housing-solutions",
  },
  {
    url: "/media-kit-corporate-locations",
    title: "SuiteAmerica Corporate Locations | 800 367 9501",
    description:
      "Find SuiteAmerica's Corporate Locations around the world. SuiteAmerica 800 367 9" +
      "501 is headquartered in El Dorado Hills, CA with more Silicon Valley corporate h" +
      "ousing offices and East Coast corporate housing offices. Contact SuiteAmerica Co" +
      "rporate Locations to book your temporary housing.",
    canonical: "https://www.suiteamerica.com/media-kit-corporate-locations",
  },
  {
    url: "/media-kit-points-of-difference",
    title: "SuiteAmerica Points of Difference | 800 367 9501",
    description:
      "With over 25 years of awarding winning corporate housing experience, SuiteAmeric" +
      "a 800 367 9501 is a corporate housing industry leader. SuiteAmerica's numerous i" +
      "ndustry awards resulted from our innovative technology and unrivaled level of se" +
      "rvice. Here are SuiteAmerica's Points of Difference.",
    canonical: "https://www.suiteamerica.com/media-kit-points-of-difference",
  },
  {
    url: "/media-kit-our-mission",
    title: "SuiteAmerica Our Mission | 800 367 9501",
    description:
      "SuiteAmerica's Mission is to provide a platinum level of service for corporate h" +
      "ousing and temporary apartments. SuiteAmerica 800 367 9501 welcomes guests to th" +
      "eir short term furnished apartments with an unrivaled level of detail and care, " +
      "making SuiteAmerica a corporate housing industry leader.",
    canonical: "https://www.suiteamerica.com/media-kit-our-mission",
  },
  {
    url: "/media-kit-press-releases",
    title: "SuiteAmerica Press Releases | 800 367 9501",
    description:
      "View SuiteAmerica 800 367 9501 press releases, the corporate housing industry le" +
      "ader. Stay updated on short term furnished apartments, innovative corporate hous" +
      "ing technology, and Destination Services. SuiteAmerica's press releases provide " +
      "updated info about the team, industry awards, and successes.",
    canonical: "https://www.suiteamerica.com/media-kit-press-releases",
  },
  {
    url: "/media-kit-who-we-are",
    title: "SuiteAmerica Who We Are | 800 367 9501",
    description:
      "As the fastest growing corporate housing company, SuiteAmerica 80" +
      "0 367 9501 has won numerous corporate housing industry awards for providing a wo" +
      "rld class level of service. SuiteAmerica's short term furnished apartments are a" +
      "vailable for all relocation and temporary housing needs.",
    canonical: "https://www.suiteamerica.com/media-kit-who-we-are",
  },
  {
    url: "/media-kit-leadership-team",
    title: "SuiteAmerica Leadership Team | 800 367 9501",
    description:
      "The SuiteAmerica 800 367 9501 Leadership Team consists of innovative corporate h" +
      "ousing industry leaders who work diligently to exceed expectations. The Leadersh" +
      "ip Team exemplifies SuiteAmerica's culture and standards of excellence while pro" +
      "viding insightful and inspiring leadership.",
    canonical: "https://www.suiteamerica.com/media-kit-leadership-team",
  },
  {
    url: "/media-kit-our-services",
    title: "SuiteAmerica Services| Corporate Housing Services | 800 367 9501",
    description:
      "SuiteAmerica's award winning corporate housing services include world class amen" +
      "ities, personalized guest packages, and 24/7 guest support. With SuiteAmerica's " +
      "800 367 9501 short term furnished apartments, guests receive Personal Transition" +
      " Services, Concierge Services, and Destination Services.",
    canonical: "https://www.suiteamerica.com/media-kit-our-services",
  },
  {
    url: "/media-kit-industry-awards",
    title:
      "SuiteAmerica Industry Awards| Corporate Housing Awards | 800 367 9501",
    description:
      "As an industry leader, SuiteAmerica 800 367 9501 has won numerous corporate hous" +
      "ing industry awards for providing temporary housing solutions with a platinum le" +
      "vel of service. View SuiteAmerica's corporate housing awards to learn about inno" +
      "vative technology and game-changing Destination Services.",
    canonical: "https://www.suiteamerica.com/media-kit-industry-awards",
  },
  {
    url: "/corporate-housing/furnished-apartments/va/alexandria",
    title:
      "Corporate Housing Alexandria, VA  |Short Term Furnished Apartments Alexandria, V" +
      "A | 800 367 9501",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in Alexandria, VA that are fully customizable come with 24/7 support & e" +
      "xclusive personalized meet & greet services. Call 800 367 9501 or Book today at " +
      "www.suiteamerica.com",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/alexandri" +
      "a",
  },
  {
    url: "/corporate-housing/furnished-apartments/va/arlington",
    title:
      "Corporate Housing Arlington, VA | Short Term Furnished Apartments  Arlington, VA" +
      "  | 800 367 9501",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in Arlington, VA. In Arlington, you can stay in a SuiteAm" +
      "erica short term furnished apartment or corporate housing while exploring Americ" +
      "a’s revolutionary history.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/arlington",
  },
  {
    url: "/corporate-housing/furnished-apartments/texas/austin",
    title:
      "Corporate Housing Austin, Texas | Short Term Furnished Apartments  Austin, Texas" +
      " | 800 367 9501",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in Austin, Texas.  With SuiteAmerica’s corporate housing and short term " +
      "furnished apartments, you can experience the Live Music Capital of the World no " +
      "matter how long your stay.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/texas/austin",
  },
  {
    url: "/corporate-housing/furnished-apartments/oregon/beaverton",
    title:
      "Corporate Housing Beaverton, Oregon | Short Term Furnished Apartments  Beaverton" +
      ", Oregon | 800 367 9501",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in Beaverton, Oregon. Beaverton’s an ideal Pacific Northw" +
      "est community with lush scenery, exceptional schools, and a high quality of life" +
      " ranking.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/oregon/beaverton",
  },
  {
    url: "/corporate-housing/furnished-apartments/ma/boston",
    title:
      "Corporate Housing Boston, MA  | Short Term Furnished Apartments  Boston, MA | 80" +
      "0 367 9501",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in Boston, MA that are fully customizable come with 24/7 support & exclu" +
      "sive personalized meet & greet services. Call 800 367 9501 or Book today at www." +
      "suiteamerica.com",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ma/boston",
  },
  {
    url: "/corporate-housing/furnished-apartments/nc/charlotte",
    title:
      "Corporate Housing Charlotte, NC  | Short Term Furnished Apartments  Charlotte, N" +
      "C | 800 367 9501",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in Charlotte, NC.  SuiteAmerica’s corporate housing and s" +
      "hort term furnished apartments allow for a world class experience in the souther" +
      "n charm and cosmopolitan energy of Charlotte.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/nc/charlotte",
  },
  {
    url: "/corporate-housing/furnished-apartments/IL/chicago",
    title:
      "Corporate Housing Chicago, IL   | Short Term Furnished Apartments  Chicago, IL |" +
      " 800 367 9501",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in Chicago, IL.  Its iconic skyline, deep dish pizza, and storied profes" +
      "sional sports teams will keep you entertained throughout your stay in SuiteAmeri" +
      "ca corporate housing or short term furnished apartments.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/IL/chicago",
  },
  {
    url: "/corporate-housing/furnished-apartments/ca/cupertino",
    title:
      "Corporate Housing Cupertino, CA  | Short Term Furnished Apartments  Cupertino, C" +
      "A | 800 367 9501",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in Cupertino, CA.  Experience one of the most well-known " +
      "and family-friendly cities in Silicon Valley while staying in SuiteAmerica’s cor" +
      "porate housing and short term apartments in Cupertino.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/cupertino",
  },
  {
    url: "/corporate-housing/furnished-apartments/texas/dallas",
    title:
      "Corporate Housing Dallas, TX | Short Term Furnished Apartments  Dallas, TX  | 80" +
      "0 367 9501",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in Dallas, TX.   Dallas is the place where cowboy hats meet cosmopolitan" +
      ". While the city might be known as a place for dramatic TV legacies, oil, and Am" +
      "erican football, it’s also become a more urban city committed to expanding its u" +
      "pscale retail centers and neighborhoods.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/texas/dallas",
  },
  {
    url: "/corporate-housing/furnished-apartments/nc/durham",
    title:
      "Corporate Housing Durham, NC  | Short Term Furnished Apartments  Durham, NC | 80" +
      "0 367 9501",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in Durham, NC. Get to know your neighbors, and everyone e" +
      "lse, while staying in SuiteAmerica’s corporate housing and short term furnished " +
      "apartments in the relaxed and beautiful city of Durham.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/nc/durham",
  },
  {
    url: "/corporate-housing/furnished-apartments/ca/fostercity",
    title:
      "Corporate Housing Foster City, CA  | Short Term Furnished Apartments  Foster Cit" +
      "y, CA | 800 367 9501",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in Foster City, CA. This small suburb is a great escape from the hustle " +
      "and bustle of larger surrounding cities, and with world class amenities and an u" +
      "nrivaled level of service, you’ll feel truly at home in Foster City while stayin" +
      "g in SuiteAmerica corporate housing or short term furnished apartments.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/fostercit" +
      "y",
  },
  {
    url: "/corporate-housing/furnished-apartments/england/london",
    title:
      "Corporate Housing London, England | Short Term Furnished Apartments  London, Eng" +
      "land | 800 367 9501",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in London, England. As the capital of England and the Uni" +
      "ted Kingdom, London’s history dates back centuries with popular landmarks that w" +
      "on’t disappoint.  From West Minister Abbey to the House of Parliament and Big Be" +
      "n, everywhere you turn, you’ll recognize parts of the city.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/england/lond" +
      "on",
  },
  {
    url: "/corporate-housing/furnished-apartments/ca/losangeles",
    title:
      "Corporate Housing Los Angeles, CA  | Short Term Furnished Apartments  Los Angele" +
      "s, CA | 800 367 9501",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in  Los Angeles, CA. . Get ready for surfing, trendy health foods, and a" +
      " whole lot of sunshine. L.A. moves fast—unless you’re on the freeway—so SuiteAme" +
      "rica’s corporate housing and short term furnished apartments provide world class" +
      " amenities with an unrivaled level of service for a relaxing and seamless reloca" +
      "tion or temporary stay.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/losangele" +
      "s",
  },
  {
    url: "/corporate-housing/furnished-apartments/oregon/portland",
    title:
      "Corporate Housing Portland, OR  | Short Term Furnished Apartments  Portland, OR " +
      " | 800 367 9501",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in  Portland, OR.  Stay in SuiteAmerica’s corporate housi" +
      "ng and short term furnished apartments in Portland to experience the laidback an" +
      "d offbeat city that prides itself on sustainability, friendliness, and being dif" +
      "ferent.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/oregon/portl" +
      "and",
  },
  {
    url: "/corporate-housing/furnished-apartments/nc/raleigh",
    title:
      "Corporate Housing Raleigh, NC | Short Term Furnished Apartments  Raleigh, NC  | " +
      "800 367 9501",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in Raleigh, NC. Raleigh an ideal city for those looking for a place with" +
      " character, a relaxed atmosphere, and great summer music festivals. SuiteAmerica" +
      "’s corporate housing and short term furnished apartments in Raleigh allow you to" +
      " enjoy this relaxed, picturesque city.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/nc/raleigh",
  },
  {
    url: "/corporate-housing/furnished-apartments/ca/redwoodcity",
    title:
      "Corporate Housing Redwood City, CA  | Short Term Furnished Apartments  Redwood C" +
      "ity, CA | 800 367 9501",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in Redwood City, CA.  With an up and coming downtown area" +
      " that boasts a vibrant and diverse food scene, Redwood City is the ideal place i" +
      "f you want to experience all that both San Francisco and the Silicon Valley have" +
      " to offer.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/redwoodci" +
      "ty",
  },
  {
    url: "/corporate-housing/furnished-apartments/ca/sandiego",
    title:
      "Corporate Housing San Diego, CA  | Short Term Furnished Apartments  San Diego, C" +
      "A | 800 367 9501",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in  San Diego, CA. In San Diego, California, every day is a beach day.  " +
      "Pack your sunglasses and some sunscreen because you’ll be busy enjoying the suns" +
      "hine while staying in SuiteAmerica’s corporate housing and short term furnished " +
      "apartments. Tacos, good vibes, and street parking await.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/sandiego",
  },
  {
    url: "/corporate-housing/furnished-apartments/ca/sanfrancisco",
    title:
      "Corporate Housing San Francisco, CA  | Short Term Furnished Apartments  San Fran" +
      "cisco, CA  | 800 367 9501",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in  San Francisco, CA. The streets might be a little stee" +
      "p, but every turn and slope in San Francisco provides a breathtaking view of the" +
      " city and Pacific Ocean. Catch a cable car while exploring diverse, exciting San" +
      " Francisco. When staying in SuiteAmerica’s corporate housing and short term furn" +
      "ished apartments, your time in S.F. is sure to be golden.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/sanfranci" +
      "sco",
  },
  {
    url: "/corporate-housing/furnished-apartments/ca/sanjose",
    title:
      "Corporate Housing San Jose, CA  | Short Term Furnished Apartments  San Jose, CA " +
      " | 800 367 9501",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in San Jose, CA.  SuiteAmerica’s corporate housing and short term furnis" +
      "hed apartments in San Jose provide world class amenities, making your relocation" +
      " or temporary stay a luxurious experience. Enjoy the innovative, bright, and act" +
      "ive lifestyle of Silicon Valley’s central hub.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/sanjose",
  },
  {
    url: "/corporate-housing/furnished-apartments/ca/santaclara",
    title:
      "Corporate Housing Santa Clara, CA  | Short Term Furnished Apartments  Santa Clar" +
      "a, CA | 800 367 9501",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in Santa Clara, CA.  While in Santa Clara, you can learn " +
      "exactly how the Silicon Valley got its name, see a San Francisco 49er game, and " +
      "enjoy thrill rides at GreatAmerica. Experience Silicon Valley’s entertainment ca" +
      "pital while staying in SuiteAmerica’s corporate housing and short term furnished" +
      " apartments.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/santaclar" +
      "a",
  },
  {
    url:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/santamoni" +
      "ca",
    title:
      "Corporate Housing Santa Monica, CA  | Short Term Furnished Apartments  Santa Mon" +
      "ica, CA  | 800 367 9501",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in Santa Monica, CA. You’ll recognize this California city by its famous" +
      " pier and palm tree lined streets. The Pacific Coast Highway will become a regul" +
      "ar part of your drive while staying in SuiteAmerica corporate housing and short " +
      "term furnished apartments in Santa Monica. Enjoy this gorgeous, trendy city with" +
      " a beach town vibe.",
    canonical: "/corporate-housing/furnished-apartments/ca/santamonica",
  },
  {
    url: "/corporate-housing/furnished-apartments/wa/seattle",
    title:
      "Corporate Housing Seattle, WA  | Short Term Furnished Apartments  Seattle, WA | " +
      "800 367 9501",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in Seattle, WA.  With plenty of rain and coffee, it might" +
      " seem like there’s not much else the Emerald City has to offer, but famous touri" +
      "st spots like the Space Needle and Pike’s Place will keep you plenty busy. Enjoy" +
      " Seattle’s fresh seafood, live music venues, and professional sports teams while" +
      " staying in SuiteAmerica’s corporate housing and short term furnished apartments" +
      ".",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/wa/seattle",
  },
  {
    url: "/corporate-housing/furnished-apartments/ca/sunnyvale",
    title:
      "Corporate Housing Sunnyvale, CA  | Short Term Furnished Apartments  Sunnyvale, CA" +
      " | 800 367 9501",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in Sunnyvale, CA. Sunnyvale’s exceptional schools, parks, and neighborho" +
      "ods make it a family-friendly city perfect for relocating or a temporary assignm" +
      "ent. Enjoy Sunnyvale’s world class amenities while staying in SuiteAmerica corpo" +
      "rate housing and short term furnished apartments.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/sunnyvale",
  },
  {
    url: "/corporate-housing/furnished-apartments/dc/washington",
    title:
      "Corporate Housing Washington, DC | Short Term Furnished Apartments  Washington, " +
      "DC | 800 367 9501",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in Washington, DC.  While staying in SuiteAmerica’s corpo" +
      "rate housing and short term furnished apartments in Washington D.C., you can see" +
      " American history up close and personal during your relocation or temporary assi" +
      "gnment.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/dc/washingto" +
      "n",
  },
  {
    url: "/corporate-housing/furnished-apartments/va/ashburn",
    title:
      "Corporate Housing Ashburn, VA  | Short Term Furnished Apartments  Ashburn, VA | " +
      "800 367 9502",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in Columbia, MD. Good schools, an array of retail centers" +
      ", and an effort to make the city more bike and walking friendly create a high qu" +
      "ality of life. Columbia prides itself on celebrating diversity and local history" +
      ". SuiteAmerica’s corporate housing and short term furnished apartments make livi" +
      "ng in Columbia affordable and ideal.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/ashburn",
  },
  {
    url: "/corporate-housing/furnished-apartments/md/columbia",
    title:
      "Corporate Housing Columbia, MD  | Short Term Furnished Apartments  Columbia, MD " +
      "| 800 367 9503",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in Fairfax, VA. Just 20 miles north of Washington D.C. are SuiteAmerica’" +
      "s corporate housing and short term furnished apartments in Fairfax, Virginia. A " +
      "small town with urban energy, Fairfax hosts concerts, festivals, movie screening" +
      "s, and more to make everyone feel at home and welcome in this quaint and energet" +
      "ic suburb.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/md/columbia",
  },
  {
    url: "/corporate-housing/furnished-apartments/va/fairfax",
    title:
      "Corporate Housing Fairfax, VA  | Short Term Furnished Apartments  Fairfax, VA | " +
      "800 367 9504",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in Fairfax, VA. Just 20 miles north of Washington D.C. are SuiteAmerica’" +
      "s corporate housing and short term furnished apartments in Fairfax, Virginia. A " +
      "small town with urban energy, Fairfax hosts concerts, festivals, movie screening" +
      "s, and more to make everyone feel at home and welcome in this quaint and energet" +
      "ic suburb.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/fairfax",
  },
  {
    url: "/corporate-housing/furnished-apartments/va/herndon",
    title:
      "Corporate Housing Herndon, VA  | Short Term Furnished Apartments  Herndon, VA | " +
      "800 367 9505",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in Herndon, VA. Herndon is a beautiful town that has a lo" +
      "t to offer during your stay in SuiteAmerica’s corporate housing and short term f" +
      "urnished apartments.  Enjoy the town’s numerous parks, free concerts, and festiv" +
      "als.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/herndon",
  },
  {
    url: "/corporate-housing/furnished-apartments/va/mclean",
    title:
      "Corporate Housing Mclean, VA | Short Term Furnished Apartments  Mclean, VA | 800" +
      " 367 9506",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in Mclean, VA. Known for luxurious homes and high-end shopping, the city" +
      " is among one of the most expensive zip codes in Virginia and is also the third " +
      "wealthiest city in the country. SuiteAmerica’s corporate housing and short term " +
      "furnished apartments in McLean are cost effective and provide world class amenit" +
      "ies in this upscale destination.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/mclean",
  },
  {
    url: "/corporate-housing/furnished-apartments/md/northbethesda",
    title:
      "Corporate Housing North Bethesda, MD  | Short Term Furnished Apartments  North B" +
      "ethesda, MD | 800 367 9507",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in North Bethesda, MD.  SuiteAmerica’s corporate housing " +
      "and short term furnished apartments in North Bethesda provide world class amenit" +
      "ies and an unrivaled level of service for those living outside Washington D.C.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/md/northbeth" +
      "esda",
  },
  {
    url: "/corporate-housing/furnished-apartments/va/reston",
    title:
      "Corporate Housing Reston, Va  | Short Term Furnished Apartments  Reston, VA | 80" +
      "0 367 9508",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in  Reston, VA.  Reston is also home to several performing arts groups a" +
      "nd a free summer concert series. SuiteAmerica’s corporate housing and short term" +
      " furnished apartments in Reston make it a great place to enjoy the outdoors whil" +
      "e also feeling at home in SuiteAmerica’s world class housing.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/reston",
  },
  {
    url: "/corporate-housing/furnished-apartments/md/silverspring",
    title:
      "Corporate Housing Silver Springs, MD  | Short Term Furnished Apartments  Silver " +
      "Springs, MD | 800 367 9509",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in  Silver Springs, MD.  Downtown Silver Spring hosts fes" +
      "tivals throughout the year, the most notable of which are Silverdocs, a document" +
      "ary film festival, and the Silver Spring Jazz Festival. Enjoy the up and coming " +
      "town of Silver Spring while staying in SuiteAmerica’s corporate housing and shor" +
      "t term furnished apartments",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/md/silverspr" +
      "ing",
  },
  {
    url: "/corporate-housing/furnished-apartments/va/tysons",
    title:
      "Corporate Housing Tysons, VA | Short Term Furnished Apartments  Tysons, VA | 800" +
      " 367 9510",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in Tysons, VA. . Tysons has lofty dreams—and plans—to be transformed int" +
      "o a walkable, sustainable urban center where people can live, work, and play. Ta" +
      "ke advantage now, and stay in SuiteAmerica’s corporate housing and short term fu" +
      "rnished apartments in Tysons before word gets out about this promising town.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/va/tysons",
  },
  {
    url: "/corporate-housing/furnished-apartments/tx/houston",
    title:
      "Corporate Housing Houston, TX | Short Term Furnished Apartments Houston, TX  | 8" +
      "00 367 9511",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in Houston, TX. As the most diverse city in Texas, Housto" +
      "n has an active art scene in the Theater District, boasts over 7 million visitor" +
      "s per year to its Museum District, and is home to several professional sports te" +
      "ams. Staying in SuiteAmerica’s corporate housing and short term furnished apartm" +
      "ents in Houston provides a world class experience in one of America’s most diver" +
      "se cities.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/tx/houston",
  },
  {
    url: "/corporate-housing/furnished-apartments/bayarea",
    title:
      "Corporate Housing Bay Area | Short Term Furnished Apartments Bay Area| 800 367 9" +
      "512",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in the San Francisco, Bay Area. Here, you won’t encounter one culture or" +
      " way of life, you’ll encounter thousands. SuiteAmerica’s corporate housing and s" +
      "hort term furnished apartments in the Bay Area allow for an opportunity to explo" +
      "re this premier California area with seven professional sports teams, numerous t" +
      "ourist attractions, iconic entertainment venues, world renowned universities, an" +
      "d breathtaking views.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/bayarea",
  },

  {
    url: "/corporate-housing/furnished-apartments/ca/mountainview",
    title:
      "Corporate Housing Mountain View, CA  |Short Term Furnished Apartments Mountain View, CA | 800 367 9501",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments in Mountain View, CA that are fully customizable come with 24/7 support & exclusive personalized meet & greet services. Call 800 367 9501 or Book today at SuiteAmerica.com",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ca/mountainview",
  },
  {
    url: "/corporate-housing/furnished-apartments/orangecounty",
    title:
      "Corporate Housing Orange County | Short Term Furnished Apartments  Orange County" +
      " | 800 367 9513",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in Orange County, Ca. Orange County is a family-friendly " +
      "place with some of the greatest theme parks in the country. Visit Disneyland or " +
      "Knott’s Berry Farm, catch an Angel’s game, learn to surf at Huntington Beach, or" +
      " go shopping at Fashion Island. Enjoy some thrills and California sunshine durin" +
      "g your stay in Orange County.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/orangecounty",
  },
  {
    url: "/corporate-housing/furnished-apartments/siliconvalley",
    title:
      "Corporate Housing Silicon Valley | Short Term Furnished Apartments  Silicon Vall" +
      "ey | 800 367 9514",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in the Silicon Valley. Balanced by family-friendly neighborhoods, excell" +
      "ent schools, and high quality of life rankings, Silicon Valley is a great place " +
      "to work and live. With SuiteAmerica’s corporate housing and short term furnished" +
      " apartments, Silicon Valley relocations and temporary assignments become a world" +
      " class experience for everyone looking to invent, invest, and enjoy life.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/siliconvalle" +
      "y",
  },
  {
    url: "/corporate-housing/furnished-apartments/ga/atlanta",
    title:
      "Corporate Housing Atlanta, GA | Short Term Furnished Apartments  Atlanta, GA | 8" +
      "00 367 9515",
    description:
      "Suite America provides platinum-level solutions in Corporate Housing & Short Ter" +
      "m Furnished Apartments in  Atlanta. GA. SuiteAmerica’s corporate housing and sho" +
      "rt term furnished apartments in Atlanta provide a world class experience in a ci" +
      "ty that’s constantly redefining American identity, activism, and global outreach" +
      ".",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ga/atlanta",
  },
  {
    url: "/corporate-housing/furnished-apartments/ny/newyork",
    title:
      "Corporate Housing New York, NY | Short Term Furnished Apartments  New York, NY |" +
      " 800 367 9516",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in New York, NY. The most populated city in the United States, NYC has f" +
      "ive distinct boroughs, each with its own unique characteristics and offerings. S" +
      "uiteAmerica’s luxurious corporate housing and short term furnished apartments in" +
      " New York City allow for a seamless relocation or temporary assignment while exp" +
      "loring the place so deeply symbolic of American ideals.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/ny/newyork",
  },
  {
    url: "/short-term-corporate-housing/spotlightcities",
    title:
      "Corporate Housing | Short Term Apartments | 800 367 9501 | SuiteAmerica",
    description:
      "Suite America provides platinum-level service in Corporate Housing, Short Term Apartments and GSA Contract Housing. At SuiteAmerica 800-367-9501, we’re using game-changing technology, 24/7 service and our nearly 30 years of excellence in customer service to revolutionize the Corporate Housing experience around the globe.",
    canonical:
      "https://www.suiteamerica.com/short-term-corporate-housing/spotlightcities",
  },
  {
    url: "/google-travel",
    title:
      "Google Corporate Housing |Google Furnished Apartments | Google GSuites Corporate Housing & Apartments",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apartments for Google Travelers that are fully customizable come with 24/7 support & exclusive personalized meet & greet services. Call 800 367 9501 or Book today at SuiteAmerica.com",
    canonical: "https://www.suiteamerica.com/google-travel",
  },
  {
    url: "/city/ca/siliconvalley",
    title:
      "Corporate Housing Silicon Valley | Short Term Furnished Apartments  Silicon Vall" +
      "ey | 800 367 9514",
    description:
      "SuiteAmerica provides game changing Corporate Housing & Short Term Furnished Apa" +
      "rtments in the Silicon Valley. Balanced by family-friendly neighborhoods, excell" +
      "ent schools, and high quality of life rankings, Silicon Valley is a great place " +
      "to work and live. With SuiteAmerica’s corporate housing and short term furnished" +
      " apartments, Silicon Valley relocations and temporary assignments become a world" +
      " class experience for everyone looking to invent, invest, and enjoy life.",
    canonical:
      "https://www.suiteamerica.com/corporate-housing/furnished-apartments/siliconvalle" +
      "y",
  },
];

var urlString = {
  getMetaData: function (reqUrl) {
    var splitUrl = reqUrl.split("/");

    if (reqUrl == "/city/ca/siliconvalley") {
      return metaTags.siliconvalley();
    }
    if (reqUrl == "/city/ca/sanfrancisco") {
      return metaTags.sanfrancisco();
    }
    if (reqUrl == "/city/wa/seattle") {
      return metaTags.seattle();
    } else if (
      splitUrl.length == 4 &&
      splitUrl[1] != "company" &&
      splitUrl[1] != "sales-managers" &&
      splitUrl[2] != "furnished-apartments"
    ) {
      return metaTags.communities(reqUrl, splitUrl);
    } else {
      var metaIndex = _.findIndex(urlsList, function (object) {
        return object.url == reqUrl;
      });
      if (metaIndex == -1) {
        return metaTags.homePage();
      } else {
        return urlsList[metaIndex];
      }
    }
  },
};

var metaTags = {
  homePage: function () {
    const reqObject = {
      title:
        "Corporate Housing | Short Term Apartments | 800 367 9501 | SuiteAmerica",
      description:
        "Suite America provides platinum-level service in Corporate Housing, Short Term A" +
        "partments and GSA Contract Housing. At SuiteAmerica 800-367-9501, we’re using ga" +
        "me-changing technology, 24/7 service and our nearly 30 years of excellence in cu" +
        "stomer service to revolutionize the Corporate Housing experience around the glob" +
        "e.",
      canonical: "https://www.suiteamerica.com/",
    };
    return reqObject;
  },
  siliconvalley: function () {
    const reqObject = {
      title:
        "Corporate housing silicon valley | Intern short term housing silicon valley",
      description:
        "Suite America offers affordable and comfortable short-term housing options for interns in Silicon Valley." +
        "Choose from a range of short-term housing options.",
      canonical: "https://www.suiteamerica.com/",
    };
    return reqObject;
  },
  sanfrancisco: function () {
    const reqObject = {
      title:
        "Corporate Housing San Francisco | Furnished Apartments San Francisco Short Term",
      description:
        "Suite America offers corporate housing, furnished apartments, and short term rentals in San Francisco." +
        "Book your stay today!.",
      canonical: "https://www.suiteamerica.com/",
    };
    return reqObject;
  },
  seattle: function () {
    const reqObject = {
      title:
        "Furnished Corporate Housing Seattle | Fully Furnished Apartments Short Term",
      description:
        "Suite America offers furnished corporate housing in Seattle." +
        "Find comfortable and convenient temporary housing options for your short-term stay.",
      canonical: "https://www.suiteamerica.com/",
    };
    return reqObject;
  },
  communities: function (requestedUrl, splitUrl) {
    const communityName = splitUrl[3].replace(new RegExp("-", "gi"), " ");
    const reqObject = {
      title:
        communityName + " | Corporate Housing | 800 367 9501 | SuiteAmerica",
      description:
        communityName +
        " Corporate Housing. Suite America provides platinum-level service in Corporate H" +
        "ousing, Short Term Apartments and GSA Contract Housing. Book today at www.suitea" +
        "merica.com or call 800 367 9501.",
      canonical: "https://www.suiteamerica.com" + requestedUrl,
    };
    return reqObject;
  },
};

module.exports = {
  metaTags,
  urlString,
};
